.about {
  padding: 100px 50px 0px;
}

ul.skills-list {
  display: grid;
  text-align: start;
  grid-template-columns: repeat(2, minmax(100px, 200px));
  gap: 0px 10px;
  padding: 0px;
  margin: 20px 0px 0px;
  overflow: hidden;
  list-style: none;
  justify-content: unset;
}

.skills-list li {
  display: flex;
  margin-bottom: 10px;
  font-weight: 500;
}

.about-section-container {
  display: flex;
  justify-content: space-between;
  padding: 30px 0 0;
}

.left-section {
  width: 60%;
}

.right-section {
  width: 35%;
  text-align: center;
}

.right-section .image {
  width: 100%;
}

.display-pic {
  width: 90%;
  border-radius: 3%;
}

.tech-heading {
  font-size: 15px;
  font-weight: 600;
}

.about-first-para {
  font-size: 16px;
  /* font-weight: 600; */
}

@media (max-width: 1000px) {
  .about {
    padding: 100px 25px 0;
  }
  .about-section-container {
    display: block;
  }
  .left-section {
    width: 100%;
  }

  .right-section {
    width: 50%;
    margin: 30px auto 0;
  }
}

@media (max-width: 800px) {
  .right-section {
    width: 70%;
  }
}

@media (max-width: 500px) {
  .about {
    padding: 100px 30px 0;
  }
  .about-section-container {
    display: block;
  }
  .left-section {
    width: 100%;
  }

  .right-section {
    width: 100%;
    margin-top: 30px;
  }
}
