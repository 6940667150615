.right-panel {
  position: fixed;
  bottom: 100px;
  top: 100px;
  right: 0;
  width: 60px;
  height: 70%;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.rotate-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  transform: rotate(90deg);
  margin-top: 20px;
  font-weight: 600;
}

.text {
  font-size: 13px;
  font-weight: 400;
  margin-top: 5px;
}

a {
  color: #000;
  text-decoration: none;
}
