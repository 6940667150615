.project-card {
  display: flex;
  margin-top: 50px;
  max-height: 350px;
  color: #323232;
  text-align: right;
  justify-content: space-between;
}
.project-card.odd {
  flex-direction: row-reverse;
  text-align: left;
}

.project-pic {
  width: 45%;
  margin: auto 0;
}
.project-heading {
  margin: 20px;
  font-size: 25px;
  font-weight: 700;
}
.project-details {
  width: 45%;
  height: inherit;
  display: flex;
  flex-direction: column;
}

.project-desc {
  background-color: #433f3f;
  padding: 15px;
  max-height: 50%;
  font-size: 15px;
  font-weight: 400;
  overflow: hidden;
  box-sizing: border-box;
  margin: auto;
  color: white;
  margin-left: -150px;
  width: calc(100% + 150px);
}

.project-card.odd .project-desc {
  margin-right: -150px;
  margin-left: 0;
  z-index: 2;
}

.desc-wrapper {
  overflow: hidden;
  height: 100%;
}

.project-links {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: auto;
  margin-bottom: 20px;
}
.project-links .links-group {
  width: 25%;
  text-align: center;
  justify-content: space-around;
  display: flex;
  font-size: 25px;
}

.project-links .links-group svg {
  cursor: pointer;
}

.project-screenshot {
  width: 100%;
  max-height: 350px;
  object-fit: cover;
  box-shadow: 14px 6px 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  border: 1px solid #32323226;
  padding: 2px;
}
.project-screenshot:hover {
  transition: transform 1s ease-in-out;
  transform: scale(1.05);
  border-radius: 1%;
  opacity: 95%;
  z-index: 3;
  position: relative;
}

/* -------------- */

@media (max-width: 1100px) {
  .project-heading {
    margin: 10px;
    font-size: 20px;
  }
  .project-pic {
    width: 55%;
  }
  .project-desc {
    max-height: 45%;
  }
  .project-links {
    margin-bottom: 15px;
  }

  .project-links .links-group {
    width: 50%;
  }
}

@media (max-width: 600px) {
  .project-desc {
    display: none;
  }

  .project-heading {
    margin: 10px;
    font-size: 15px;
  }

  .project-pic {
    width: 70%;
  }

  .project-links {
    margin-bottom: 10px;
  }

  .project-links .links-group {
    width: 65%;
  }
}
