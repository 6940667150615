.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  background-color: #433f3ff2;
  z-index: 10;
}

.logo img {
  height: 50px;
  width: 50px;
}

.sections a {
  margin-left: 20px;
  text-decoration: none;
  color: white;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
}

a.resume-button {
  color: #323232;
}

.resume-button {
  color: #323232;
  background-color: transparent;
  border: 1px solid #323232;
  border-radius: 4px;
  padding: 0.75rem 1rem;
  line-height: 1;
  text-decoration: none;
  margin-left: 15px;
}

.sections a:hover {
  border-bottom: 1px #323232 solid;
}

.header {
  position: sticky;
  top: 0px;
  height: 50px;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
}

.header.hide {
  top: -90px;
}

.glow-on-hover {
  border: none;
  outline: none;
  color: #323232;
  background: #fffef4;
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
}

.glow-on-hover:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover:active {
  color: #000;
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #db9c9c;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 400% 0;
  }
  100% {
    background-position: 0 0;
  }
}

/* ------------------- */
.home-nav {
  color: #fffef4;
}

.sections {
  display: flex;
  align-items: center;
}

.hamburger-button {
  display: none;
  cursor: pointer;
  margin-left: 1rem;
}

@media (min-width: 700px) {
  .hamburger-button-section {
    display: none;
  }
}

.hamburger-close {
  font-size: 25px;
  margin-bottom: 20px;
  cursor: pointer;
}

@media (max-width: 700px) {
  .sections {
    display: none;
  }

  .hamburger-button {
    display: block;
    color: white;
    font-size: 25px;
  }

  .side-menu {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #fff;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .side-menu a {
    font-size: 1.5rem;
    margin: 1rem;
    color: #333;
    transition: color 0.3s ease-in-out;
    cursor: pointer;
  }

  .side-menu a:hover:not(.resume-button) {
    color: #f39c12;
  }
  .side-menu svg:hover {
    color: #f39c12;
  }
}
