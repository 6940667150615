.experience {
  padding: 100px 50px 0px;
}

@media (max-width: 1000px) {
  .experience {
    padding: 100px 25px 0;
  }
}

@media (max-width: 500px) {
  .experience {
    padding: 100px 30px 0;
  }
}
