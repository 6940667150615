.contact {
  padding: 100px 50px 50px;
}

.contact-section {
  text-align: center;
  margin-top: 30px;
}

a.email-button {
  color: #fffef4;
}

.email-button {
  background-color: #433f3f;
  padding: 13px;
}

.email-btn {
  margin-top: 30px;
}

@media (max-width: 1000px) {
  .contact {
    padding: 100px 25px 50px;
  }
}

@media (max-width: 500px) {
  .contact {
    padding: 100px 30px 50px;
  }
}
