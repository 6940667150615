.all-sections {
  padding: 0 60px;
}

.section-heading {
  font-size: 18px;
  /* background-color: #db9c9c; */
  margin: 0;
  border-bottom: 2px solid;
  padding: 10px;
  box-shadow: 0px 3px 9px -4px;
}

.display-pic {
  box-shadow: 9px 12px 13px 2px rgba(0, 0, 0, 0.3);
}

/* ----------------- */

@media (max-width: 500px) {
  .all-sections {
    padding: 10px;
  }
}
