.main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.wrapper {
  flex: 1;
}
.wrong-url {
  justify-content: center;
  display: flex;
  padding: 50px;
}

@media (min-width: 500px) {
  .left-panel {
    position: fixed;
    bottom: 100px;
    top: 100px;
    left: 0;
    width: 60px;
    height: 70%;
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  li {
    margin-bottom: 20px;
  }
}
@media (max-width: 500px) {
  .left-panel {
    margin: 10px 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  li {
    margin-left: 20px;
  }
}
